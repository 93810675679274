import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

const data = [
  {
    "id": "Bedford",
    "color": "hsl(325, 70%, 50%)",
    "data": [
      {
        "x": "Nov10",
        "y": 27
      },
      {
        "x": "Nov12",
        "y": 29
      },
      {
        "x": "Nov13",
        "y": 29
      },
      {
        "x": "Nov16",
        "y": 42
      },
      {
        "x": "Nov17",
        "y": 58
      },
      {
        "x": "Nov18",
        "y": 61
      },
      {
        "x": "Nov19",
        "y": 66
      },
      {
        "x": "Nov20",
        "y": 73
      },
      {
        "x": "Nov23",
        "y": 86
      },
      {
        "x": "Nov24",
        "y": 86
      },
      {
        "x": "Nov25",
        "y": 91
      },
      {
        "x": "Nov30",
        "y": 111
      },
      {
        "x": "Dec01",
        "y": 107
      },
      {
        "x": "Dec03",
        "y": 116
      },
      {
        "x": "Dec04",
        "y": 126
      },
      {
        "x": "Dec07",
        "y": 149
      },
      {
        "x": "Dec08",
        "y": 144
      },
      {
        "x": "Dec09",
        "y": 146
      },
      {
        "x": "Dec10",
        "y": 147
      },
      {
        "x": "Dec11",
        "y": 141
      }
    ]
  },
  {
    "id": "Lewisboro",
    "color": "hsl(279, 70%, 50%)",
    "data": [
      {
        "x": "Nov10",
        "y": 14
      },
      {
        "x": "Nov12",
        "y": 13
      },
      {
        "x": "Nov13",
        "y": 18
      },
      {
        "x": "Nov16",
        "y": 21
      },
      {
        "x": "Nov17",
        "y": 24
      },
      {
        "x": "Nov18",
        "y": 29
      },
      {
        "x": "Nov19",
        "y": 33
      },
      {
        "x": "Nov20",
        "y": 41
      },
      {
        "x": "Nov23",
        "y": 45
      },
      {
        "x": "Nov24",
        "y": 43
      },
      {
        "x": "Nov25",
        "y": 48
      },
      {
        "x": "Nov30",
        "y": 70
      },
      {
        "x": "Dec01",
        "y": 71
      },
      {
        "x": "Dec03",
        "y": 77
      },
      {
        "x": "Dec04",
        "y": 86
      },
      {
        "x": "Dec07",
        "y": 85
      },
      {
        "x": "Dec08",
        "y": 86
      },
      {
        "x": "Dec09",
        "y": 84
      },
      {
        "x": "Dec10",
        "y": 83
      },
      {
        "x": "Dec11",
        "y": 81
      }
    ]
  },
  {
    "id": "Mt Kisco",
    "color": "hsl(213, 90%, 90%)",
    "data": [
      {
        "x": "Nov10",
        "y": 32
      },
      {
        "x": "Nov12",
        "y": 45
      },
      {
        "x": "Nov13",
        "y": 47
      },
      {
        "x": "Nov16",
        "y": 59
      },
      {
        "x": "Nov17",
        "y": 62
      },
      {
        "x": "Nov18",
        "y": 65
      },
      {
        "x": "Nov19",
        "y": 68
      },
      {
        "x": "Nov20",
        "y": 76
      },
      {
        "x": "Nov23",
        "y": 79
      },
      {
        "x": "Nov24",
        "y": 78
      },
      {
        "x": "Nov25",
        "y": 80
      },
      {
        "x": "Nov30",
        "y": 109
      },
      {
        "x": "Dec01",
        "y": 112
      },
      {
        "x": "Dec03",
        "y": 126
      },
      {
        "x": "Dec04",
        "y": 138
      },
      {
        "x": "Dec07",
        "y": 153
      },
      {
        "x": "Dec08",
        "y": 152
      },
      {
        "x": "Dec09",
        "y": 146
      },
      {
        "x": "Dec10",
        "y": 146
      },
      {
        "x": "Dec11",
        "y": 152
      }
    ]
  },
  {
    "id": "North Salem",
    "color": "hsl(262, 70%, 50%)",
    "data": [
      {
        "x": "Nov10",
        "y": 9
      },
      {
        "x": "Nov12",
        "y": 9
      },
      {
        "x": "Nov13",
        "y": 9
      },
      {
        "x": "Nov16",
        "y": 13
      },
      {
        "x": "Nov17",
        "y": 15
      },
      {
        "x": "Nov18",
        "y": 19
      },
      {
        "x": "Nov19",
        "y": 19
      },
      {
        "x": "Nov20",
        "y": 24
      },
      {
        "x": "Nov23",
        "y": 26
      },
      {
        "x": "Nov24",
        "y": 29
      },
      {
        "x": "Nov25",
        "y": 31
      },
      {
        "x": "Nov30",
        "y": 38
      },
      {
        "x": "Dec01",
        "y": 39
      },
      {
        "x": "Dec03",
        "y": 38
      },
      {
        "x": "Dec04",
        "y": 44
      },
      {
        "x": "Dec07",
        "y": 43
      },
      {
        "x": "Dec08",
        "y": 41
      },
      {
        "x": "Dec09",
        "y": 36
      },
      {
        "x": "Dec10",
        "y": 36
      },
      {
        "x": "Dec11",
        "y": 35
      }
    ]
  },
  {
    "id": "Pound Ridge",
    "color": "hsl(171, 70%, 50%)",
    "data": [
      {
        "x": "Nov10",
        "y": 4
      },
      {
        "x": "Nov12",
        "y": 5
      },
      {
        "x": "Nov13",
        "y": 5
      },
      {
        "x": "Nov16",
        "y": 7
      },
      {
        "x": "Nov17",
        "y": 6
      },
      {
        "x": "Nov18",
        "y": 7
      },
      {
        "x": "Nov19",
        "y": 7
      },
      {
        "x": "Nov20",
        "y": 9
      },
      {
        "x": "Nov23",
        "y": 10
      },
      {
        "x": "Nov24",
        "y": 10
      },
      {
        "x": "Nov25",
        "y": 11
      },
      {
        "x": "Nov30",
        "y": 17
      },
      {
        "x": "Dec01",
        "y": 17
      },
      {
        "x": "Dec03",
        "y": 17
      },
      {
        "x": "Dec04",
        "y": 18
      },
      {
        "x": "Dec07",
        "y": 22
      },
      {
        "x": "Dec08",
        "y": 20
      },
      {
        "x": "Dec09",
        "y": 21
      },
      {
        "x": "Dec10",
        "y": 21
      },
      {
        "x": "Dec11",
        "y": 19
      }
    ]
  },
  {
    "id": "Somers",
    "color": "#666666",
    "data": [
      {
        "x": "Nov10",
        "y": 28
      },
      {
        "x": "Nov12",
        "y": 30
      },
      {
        "x": "Nov13",
        "y": 31
      },
      {
        "x": "Nov16",
        "y": 42
      },
      {
        "x": "Nov17",
        "y": 46
      },
      {
        "x": "Nov18",
        "y": 57
      },
      {
        "x": "Nov19",
        "y": 66
      },
      {
        "x": "Nov20",
        "y": 68
      },
      {
        "x": "Nov23",
        "y": 81
      },
      {
        "x": "Nov24",
        "y": 86
      },
      {
        "x": "Nov25",
        "y": 89
      },
      {
        "x": "Nov30",
        "y": 96
      },
      {
        "x": "Dec01",
        "y": 93
      },
      {
        "x": "Dec03",
        "y": 92
      },
      {
        "x": "Dec04",
        "y": 103
      },
      {
        "x": "Dec07",
        "y": 106
      },
      {
        "x": "Dec08",
        "y": 103
      },
      {
        "x": "Dec09",
        "y": 107
      },
      {
        "x": "Dec10",
        "y": 104
      },
      {
        "x": "Dec11",
        "y": 106
      }
    ]
  },
  {
    "id": "Yorktown",
    "color": "#666666",
    "data": [
      {
        "x": "Nov10",
        "y": 48
      },
      {
        "x": "Nov12",
        "y": 73
      },
      {
        "x": "Nov13",
        "y": 84
      },
      {
        "x": "Nov16",
        "y": 113
      },
      {
        "x": "Nov17",
        "y": 133
      },
      {
        "x": "Nov18",
        "y": 146
      },
      {
        "x": "Nov19",
        "y": 150
      },
      {
        "x": "Nov20",
        "y": 168
      },
      {
        "x": "Nov23",
        "y": 175
      },
      {
        "x": "Nov24",
        "y": 180
      },
      {
        "x": "Nov25",
        "y": 180
      },
      {
        "x": "Nov30",
        "y": 158
      },
      {
        "x": "Dec01",
        "y": 159
      },
      {
        "x": "Dec03",
        "y": 174
      },
      {
        "x": "Dec04",
        "y": 203
      },
      {
        "x": "Dec07",
        "y": 230  
      },
      {
        "x": "Dec08",
        "y": 230
      },
      {
        "x": "Dec09",
        "y": 225
      },
      {
        "x": "Dec10",
        "y": 239
      },
      {
        "x": "Dec11",
        "y": 251
      }
    ]
  }
].reverse()

ReactDOM.render(
  <React.StrictMode>
    <div style={{height:"70vh",width:"80vw"}}>
      <h2 style={{textAlign:"center",fontFamily:"Arial, Helvetica, sans-serif",color:"#444"}}>Local COVID Cases - 2020</h2>
    <App data={data}/></div>
    <div style={{textAlign:"center",paddingRight:"250px",marginTop:"50px",fontFamily:"Arial, Helvetica, sans-serif"}}>
      Last Updated: 12/11/2020 <span style={{paddingLeft:"25px",fontSize:".8em"}}><a href="https://www.westchestergov.com/covid-19-cases" target="_wgnew">Data Source</a></span>
    </div>
  </React.StrictMode>,
  document.getElementById('root')
);
